<template>
  <b-card-code>
    <b-row class="mb-2">
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :to="'/cda/accounts-accounting'"
        >
          Regresar
        </b-button>
      </b-col>
    </b-row>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Centro de Costo"
            label-for="codigo"
          >
            <b-form-input
              id="codigo"
              v-model="item.code"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Nº Cuenta Contable"
            label-for="codigo"
          >
            <b-form-input
              id="codigo"
              v-model="item.accountNumber"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Cuenta Contable"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="item.accountName"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Unidad"
            label-for="unit"
          >
            <b-form-input
              id="unit"
              v-model="item.unit"
              type="text"
              minlength="4"
              maxlength="4"
              size="4"
              :disabled="items.length > 0"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Tipo"
            label-for="typeAux"
          >
            <v-select
              v-model="item.type"
              label="label"
              :options="types"
              :disabled="items.length > 0"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="mt-3 mb-1">
            Datos Auxiliares
          </h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Centro de Costo de Destino"
            label-for="distCenter"
          >
            <v-select
              v-model="subItem.destinationCenter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="centers"
              label="centro"
              class="assignee-selector"
            >
              <template slot="option"  slot-scope="option">
                {{ option.idCentro }} - {{ option.centro }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Unidades"
            label-for="subItemUnit"
          >
            <b-form-input
              id="subItemUnit"
              v-model="subItem.units"
              type="number"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mt-2"
        >
          <b-button-group>
            <b-button
              variant="primary"
              @click="add(subItem)"
            >
              Agregar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="loading || !item.unit || !item.type"
            >
              <span v-if="!loading">Guardar</span>
              <span v-if="loading">Un momento...</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <p>{{ errorMessage }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-table
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="remove(data)"
              >
                Borrar
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BTable, BButtonGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BButtonGroup,
    BTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      centers: [],
      columns: [
        {
          label: 'Centro de Costo de Destino',
          key: 'destinationCenter.centro',
        },
        {
          label: 'Unidades',
          key: 'units',
        },
        {
          label: 'Estado',
          key: 'status.status',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
      errorMessage: '',
      generateOptions: ['S', 'N'],
      loading: true,
      item: {},
      items: [],
      subItem: {},
      types: [
        { label: 'Numerico', code: 'N' },
        { label: 'Porcentual', code: 'P' },
      ],
      options: {
        prefix: {
          prefix: ' %',
          numeralIntegerScale: 2,
          numeralDecimalScale: 2,
          numeral: true,
          tailPrefix: true,
          numeralPositiveOnly: true,
          rawValueTrimPrefix: true,
        },
      },
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  created() {
    this.load()
  },
  methods: {
    async push(item) {
        let addOk = true
        const index = this.items.findIndex(i => item.destinationCenter.idCentro === i.destinationCenter.idCentro)

        if (index > -1) {
          const result = await this.$swal({
            title: 'Existe un registro asociado al centro de costo seleccionado.',
            text: "¿Estas seguro que deseas reemplazarlo?",
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })

          if (result.value) {
            this.items.splice(index, 1)
          } else {
            addOk = false
          }
        }

        if(addOk) {
          this.items.push({
            units: item.units,
            destinationCenter: item.destinationCenter,
            status: {status: 'Activo'},
          })


          this.subItem.destinationCenter = null
          this.subItem.units = ''
          // this.item.unit = parseInt(item.units, 10)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Agregado',
              icon: 'CoffeeIcon',
              variant: 'Info',
            },
          })
        }
    },
    async add(item) {
      this.errorMessage = ''
      if (item && item.destinationCenter && Number(item.units)) {
        if (this.item.type && this.item.type.code === 'P') {
          const total = this.items.reduce((a, b) => a + Number(b.units), Number(item.units))
          if (total <= 100) {
            this.push(item)
          } else {
            this.errorMessage = 'Valor de porcentaje mayor a 100'
          }
        } else {
          this.push(item)
        }
      } else {
        this.errorMessage = 'Datos no validos'
      }
    },
    async remove(data) {
      this.items = this.items.filter(item => item.destinationCenter.idCentro !== data.item.destinationCenter.idCentro) || []

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Borrado',
          icon: 'CoffeeIcon',
          variant: 'Info',
        },
      })
    },
    async load() {
      try {
        this.item.code = this.$route.query.centerCode
        this.item.accountName = this.$route.query.accountName
        this.item.accountNumber = this.$route.query.accountNumber

        this.loading = true
        const resp = await this.$http.get(`${environment.uri}/ccenters/all`)
        this.centers = resp.data.sort((a, b) => (a.idCentro > b.idCentro) ? 1 : -1)

        const resp2 = await this.$http.get(`${environment.uri}/accounts/auxiliar/idCuenta/${this.item.accountNumber}/idCentro/${this.item.code}`)

        if (!resp2 || !resp2.data || !resp2.data.length) {
          this.errorMessage = ''
          return
        }

        this.item.unit = resp2.data[0].idUnidad
        this.item.type = { ...this.types.find(s => s.code === resp2.data[0].idTipoAuxiliar) }

        resp2.data.forEach(item => {
          const push = {
            destinationCenter: this.centers.find(s => s.idCentro === item.idCentroDestino),
            units: item.idUnidades,
            status: this.statuses.find(s => s.idStatus === item.idStatus),
          }

          this.items.push(push)
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Algo salio mal, intentalo mas tarde'
      } finally {
        this.loading = false
      }
    },
    async save(item) {
      try {
        this.loading = true
        this.errorMessage = ''
        await this.$http.post(`${environment.uri}/accounts/auxiliar`, {
          accountName: item.accountName,
          type: item.type,
          accountNumber: parseInt(item.accountNumber, 10),
          code: parseInt(item.code, 10),
          unit: item.unit,
          items: this.items,
        })

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Guardado',
            icon: 'CoffeeIcon',
            variant: 'Info',
          },
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
        this.loading = false

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'No fue posible procesar su solicitud',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
